<template>
    <page heading="New Category">
        <input-field
            label="Name"
            name="name"
            id="name"
            v-model="name"
        />

        <v-button @click="submitForm">Create</v-button>
    </page>
</template>

<script>
import Page from '../../layouts/Page.vue';
import InputField from '../../components/Form/Input.vue';
import VButton from '../../components/Action/VButton.vue';
import to from '../../plugins/to';
import api from '../../plugins/api';

export default {
    name: 'AlbumsCreate',
    components: {
        VButton,
        InputField,
        Page,
    },
    methods: {
        async submitForm() {
            const [err] = await to(api.post('/categories', {
                name: this.name,
            }));

            if (!err) {
                await this.$router.push({ name: 'dashboard.categories' });
            } else {
                // TODO
            }
        },
    },
    data: () => ({
        name: '',
    }),
};
</script>
